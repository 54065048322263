<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行，添加按钮 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button type="primary" @click="showAddProductCategoryDialog"
            >+ 添加业态下分类</el-button
          >
        </el-col>
      </el-row>
      <!-- 内容主体 -->
      <el-table :data="productList" stripe border>
        <!-- 第一列id -->
        <el-table-column
          label="分类id"
          width="100"
          aligin="center"
          prop="productCategoryId"
        >
        </el-table-column>
        <!-- 第二列分类图标 -->
        <!-- <el-table-column label="分类图片" width="100" aligin="center">
          <template slot-scope="scope">
            <img :src="scope.row.icon" />
          </template>
        </el-table-column> -->
        <!-- 第三列业态名称 -->
        <el-table-column label="商品分类" aligin="center" prop="name">
        </el-table-column>
        <!-- <el-table-column prop="icon" label="分类图标">
          <template slot-scope="scope">
            <img class="logoImg" :src="scope.row.icon" />
          </template>
        </el-table-column> -->
        <!-- 第四列操作 -->
        <el-table-column label="操作" aligin="center">
          <template slot-scope="scope">
            <!-- 删除按钮 -->
            <el-button
              size="mini"
              type="danger"
              @click="removeProductCategoryById(scope.row.productCategoryId)"
            >
              删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加对应摊位下的商品分类对话框 -->
    <el-dialog
      title="添加业态下分类"
      :visible.sync="addMerchantProductDialogVisible"
      width="500px"
      @close="clearInput"
    >
      <div class="add">
        <div>
          选择商品分类：
          <el-select
            size="medium"
            v-model="merchantCategoryProductCategoryRelForm.productCategoryIds"
            multiple
            placeholder="请选择商品分类"
          >
            <el-option
              v-for="item in productFirstCateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <el-button @click="addGoodsCateDialogVisible = true" type="primary"
            >新增商品分类</el-button
          >
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addMerchantProductDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="submitMerchantProduct"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 新增商品分类 -->
    <el-dialog
      title="添加商品分类"
      :visible.sync="addGoodsCateDialogVisible"
      width="30%"
      @close="AddDialogClosed"
      append-to-body
    >
      <!-- 内容主体区域 -->
      <el-form
        ref="AddFormRef"
        :model="AddProductForm"
        :rules="AddFormRules"
        label-width="80px"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="AddProductForm.name" clearable></el-input>
        </el-form-item>
        <!-- <el-form-item label="商品图标" prop="icon">
          <el-upload
            class="avatar-uploader"
            action="/api/v1/file/file"
            :data="this.bizType1"
            :before-upload="beforeUpload"
            :on-success="handlesuccess1"
            :show-file-list="false"
          >
            <img
              v-if="AddProductForm.icon"
              :src="AddProductForm.icon"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item> -->
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGoodsCateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getAddProduct">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询入参
      queryInfo: {
        limit: 15,
        pageNo: 1,
        // 上个页面传过来的业态id
        merchantCategoryId: ''
      },
      // 商品分类
      productList: [],
      // 总数
      total: 0,
      // 已有的商品分类数组
      hasProductList: [],
      // 删除商品类别入参
      merchantCategoryProductCategoryRelForm: {
        merchantCategoryId: '', // 商户摊位类型id
        productCategoryIds: [] // 商品类型id
      },
      // 添加摊位下商品分类
      addMerchantProductDialogVisible: false,
      // 商品一级分类数组
      productFirstCateList: [],
      // 比对后的数组
      productSecondCateList: [],
      // 绑定的所选商品分类
      // productValue: [],
      // 调用一级商品分类入参
      queryInfoForProduct: {
        limit: 100,
        pageNo: 1,
        parentId: 0
      },
      // 新增商品分类
      addGoodsCateDialogVisible: false,
      // 添加商品分类的表单
      AddProductForm: {
        name: '',
        parentId: '0'
        // icon: ''
      },
      // 添加商品分类的校验规则
      AddFormRules: {
        name: [
          { required: true, message: '请输入商品类别名称', trigger: 'blur' }
        ]
      },
      bizType1: {
        bizType: 'goodsStyle'
      }
    }
  },
  created() {
    // 存入到session里，用于切换tab使用
    // 路由取参数
    this.queryInfo.merchantCategoryId = window.sessionStorage.getItem(
      'merchantCategoryId'
    )
    this.merchantCategoryProductCategoryRelForm.merchantCategoryId = window.sessionStorage.getItem(
      'merchantCategoryId'
    )
    // console.log(this.merchantCategoryProductCategoryRelForm.merchantCateId)

    // 调用查询对应类别接口
    this.getProductCate()
    this.getProduct()
  },
  methods: {
    // 调用获取一级商品分类
    async getProduct() {
      const { data: res } = await this.$http.get('productCategory/list', {
        params: this.queryInfoForProduct
      })
      if (res.code !== 0) {
        return this.$message.error('获取一级商品分类失败')
      }
      // 这里接口有bug，赋值可能会有变动
      this.productFirstCateList = res.data.data
      console.log(this.productFirstCateList)
    },
    // 打开新增商品类别
    showAddProductCategoryDialog() {
      // 调用一级商品分类
      this.getProduct()
      this.addMerchantProductDialogVisible = true
    },
    // 查看对应类别接口
    async getProductCate() {
      const { data: res } = await this.$http.get(
        'merchantProductCategoryRel/list',
        {
          params: this.queryInfo
        }
      )
      if (res.code !== 0) {
        return this.$message.error('获取商品分类失败')
      }
      // 请求成功后进行赋值
      this.productList = res.data.data
      // console.log(this.productList)
      this.total = res.data.total
      // 提取已有的商品分类
      this.hasProductList = this.productList.map(function(item, index) {
        return item.productCategoryId
      })
      // this.getProduct()
      console.log(this.hasProductList)
      var arr = this.productFirstCateList.filter(function(item) {
        return this.hasProductList.indexOf(item.id) === -1
      })
      console.log(arr)
      // var arr = this.hasProductList

      // this.productSecondCateList = this.productFirstCateList.filter(function(
      //   item
      // ) {
      //   return arr.indexOf(item.id) === -1
      // })
      // console.log(this.productSecondCateList)
    },
    // 删除操作
    async removeProductCategoryById(productCateID) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该分类，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      this.merchantCategoryProductCategoryRelForm.productCategoryIds = [
        productCateID
      ]
      const { data: res } = await this.$http.delete(
        'merchantProductCategoryRel',
        {
          data: this.merchantCategoryProductCategoryRelForm
        }
      )
      if (res.code !== 0) {
        return this.$message.error('删除分类失败')
      }
      this.$message.success('删除分类成功')
      // 清空类别
      this.merchantCategoryProductCategoryRelForm.productCategoryIds = []
      this.getProductCate()
    },
    // 提交添加信息
    async submitMerchantProduct() {
      const { data: res } = await this.$http.post(
        'merchantProductCategoryRel',
        this.merchantCategoryProductCategoryRelForm
      )
      if (res.code !== 0) {
        return this.$message.error('添加摊位业态下商品分类失败')
      }
      this.$message.success('添加摊位业态下商品分类成功')
      this.getProductCate()
      this.addMerchantProductDialogVisible = false
    },
    // limit变化，调用列表接口
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getProductCate()
    },
    // 页码变化，调用接口
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage
      this.getProductCate()
    },
    // 清空输入框
    clearInput() {
      this.merchantCategoryProductCategoryRelForm.productCategoryIds = []
    },
    // 提交添加商品分类对话框
    getAddProduct() {
      this.$refs.AddFormRef.validate(async valid => {
        if (!valid) return
        const res = await this.$http.post(
          '/productCategory',
          this.AddProductForm
        )
        if (res.data.code !== 0) return this.$message.error(res.data.message)
        this.$message.success('添加商品分类成功')
        this.addGoodsCateDialogVisible = false
        this.getProduct()
      })
    },
    // 监听添加分类对话框的关闭
    AddDialogClosed() {
      this.$refs.AddFormRef.resetFields()
    },
    beforeUpload(file) {
      const types = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp']
      const isImage = types.includes(file.type)
      if (!isImage) {
        this.$message.error('上传图片只能是jpg、jpeg、bmp、png格式')
        return false
      }
      return true
    },
    handlesuccess1(response, file, fileList) {
      this.$forceUpdate()
      this.AddProductForm.icon = response.data
    }
  }
}
</script>

<style lang="less" scoped>
img {
  width: 70px;
  height: 70px;
}
.add {
  display: flex;
  justify-content: space-between;
}
</style>
